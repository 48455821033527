import React, { Component, useEffect, useRef } from 'react'

export default function Comments() {
  const commentBox = useRef<HTMLDivElement>()

  useEffect(() => {
    let scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://utteranc.es/client.js')
    scriptEl.setAttribute('crossorigin', 'anonymous')
    scriptEl.setAttribute('async', '')
    scriptEl.setAttribute('repo', 'trungvose/blog-comments')
    scriptEl.setAttribute('issue-term', 'pathname')
    scriptEl.setAttribute('theme', 'preferred-color-scheme')
    if(commentBox.current) {
      commentBox.current.appendChild(scriptEl)
    }
  }, [])

  return (
    <>
      <div ref={commentBox} style={{
        marginTop: '50px',
        marginBottom: '100px',
      }} />
    </>
  )
}
