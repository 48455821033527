import { graphql } from 'gatsby'
import React from 'react'
import Comments from '../components/Comments/Comments'
import Layout from '../components/Layout'
import PageTemplateDetails from '../components/PageTemplateDetails'
import { SEO } from '../components/SEO'
import { generateJsonLd } from '../components/util'

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const {
      frontmatter: { title, description },
      fields: { slug },
    } = page

    return (
      <Layout>
        <React.Fragment>
          <SEO pageTitle={title} pageDescription={description}>
            <script type="application/ld+json">
              {generateJsonLd(
                title,
                description,
                `https://trungvose.com${slug}`,
                slug.includes('about') ? 'AboutPage' : 'WebPage'
              )}
            </script>
          </SEO>
          <PageTemplateDetails {...this.props}>
            <Comments />
          </PageTemplateDetails>
        </React.Fragment>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        updated
      }
      fields {
        tagSlugs
        slug
      }
    }
  }
`
