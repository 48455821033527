import React from 'react'
import moment from 'moment'
import './style.scss'

class PageTemplateDetails extends React.Component {
  render() {
    const page = this.props.data.markdownRemark;
    let { title, updated } = page.frontmatter;
    const { children } = this.props;

    return (
      <React.Fragment>
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">{title}</h1>
              <div
                className="page__body"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
              <div className="page-updated">
                <em>Last Updated {moment(updated).format('D MMM YYYY')}</em>
              </div>
            </div>
          </div>
          {children}
        </div>
      </React.Fragment>
    )
  }
}

export default PageTemplateDetails
